<template>
  <div>
    <div class="style-box-setting index-style-user">
      <div class="style-box-setting-header">
        <v-row>
          <v-col cols="4">
            <div class="title-header">
              Gestion des configurations
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="sub-menu-simple">
      <menu-configuration :itemsConfig="menuConfiguration"></menu-configuration>
      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MenuConfiguration from './MenuConfiguration.vue';
export default {
  name: 'Gestion-des-configurations',
  data() {
    return {
      menuConfiguration: [
        {
          name: 'Configuration',
          href: '/setting/gestion-configuration',
          code: 'GLCTH'
        },
        {
          name: 'Famille Support',
          href: '/setting/gestion-configuration/famille',
          code: 'GDFC'
        }
      ]
    };
  },
  components: {
    MenuConfiguration
  },
  methods: {
    ...mapActions(['getAllDataForFilterObligee'])
  },
  mounted() {
    if (this.$route.path === '/setting/users/permissions') {
      this.selectedMenu = 'permission';
    } else if (this.$route.path === '/setting/users/profils') {
      this.selectedMenu = 'role';
    } else if (
      this.$route.path === '/setting/users/gestion-societe-permission'
    ) {
      this.selectedMenu = 'societe';
    } else if (this.$route.path === '/setting/users/familles') {
      this.selectedMenu = 'famille';
    } else {
      this.selectedMenu = 'users';
    }
    this.getAllDataForFilterObligee();
  }
};
</script>
