<template>
  <div class="menu-config">
    <!-- MENU CONFIGURATIONS  -->
    <div class="list-tabs">
      <v-tabs
        :hide-slider="false"
        slider-color="#704ad1"
        color="#704ad1"
        item-color="#704ad1"
        v-model="tabValue"
      >
        <v-tab
          v-for="(item, index) in itemsConfig"
          :key="index"
          :href="`#tab-${index}`"
          color="#704ad1"
          item-color="#704ad1"
        >
          <router-link
            :to="item.href"
            class="font-tabs-facture"
            v-if="checkPermission(item.code)"
          >
            {{ item.name }}
          </router-link>
        </v-tab>
      </v-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    itemsConfig: { required: true },
    model: {
      required: false
    }
  },
  data() {
    return { tabValue: this.model };
  },
  computed: {
    ...mapGetters(['checkPermission'])
  }
};
</script>
<style scoped lang="scss">
.menu-config {
  height: 30px;
  z-index: 999;
  background: #fff;

  .list-tabs {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;

    .v-tabs {
      width: max-content;
    }

    .v-application .deep-purple {
      background-color: #704ad1 !important;
      border-color: #704ad1 !important;
      width: 100%;
    }
  }
}

.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;

    &.router-link-exact-active,
    &.v-tab--active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;

      a {
        color: #704ad1 !important;
        font-size: 11px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        white-space: nowrap;
      }

      // line-height: 42px;
      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      text-decoration: none;
      background-color: #f1edfb;
    }
  }
}

.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0px;
  padding: 0px;
}
</style>
<!-- <style lang="scss">
.v-tabs-bar {
  border-radius: inherit;
  height: 30px;
}
</style> -->
